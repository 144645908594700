import React from "react";
import {StaticImage} from "gatsby-plugin-image";

import {Link} from "gatsby";
import {ArrowCircleDownIcon} from "@heroicons/react/outline";

import {Link as ScrollLink} from "react-scroll";
import {MeetingButton} from "./MeetingButton";


export default function MainSlider() {

  return (
    <div className='relative min-h-full md:h-fit max-h-screen overflow-hidden'>
      <StaticImage
        src="../images/dlaczego-warto2.jpg"
        loading="eager"
        formats={["auto", "jpg", "webp"]}
        alt="dlaczego warto?"
        className=" object-cover min-h-screen	"
      />
      <div className='absolute top-0 w-full h-full flex flex-col items-start justify-center space-y-6 bg-gradient-to-r from-[#CEB995]/50'>
        <div className="w-full sm:w-2/3 px-4 sm:px-6 lg:px-8 ">
          <h1 className="text-4xl tracking-tight font-extrabold font-sans-2 text-white sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Wróć do cieszenia się </span>{' '}
            <span className="block xl:inline">najlepszymi chwilami życia</span>
          </h1>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <MeetingButton/>
          </div>
        </div>
      </div>
      <div className="absolute bottom-5 text-white w-full flex items-center justify-center">
        <ScrollLink to="roszczenia" className="cursor-pointer" smooth={true} duration={500}>
          <ArrowCircleDownIcon className="w-8 h-8 animate-bounce"/>
        </ScrollLink>
      </div>
    </div>
  )

}
