import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {ChevronRightIcon} from "@heroicons/react/outline";

export default function Testimony() {
  return (
    <div className="bg-[#F0EAE3] py-20 ">
      <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-2 relative">
        <figure>
          <div className="flex text-slate-900 sm:justify-center">
            <div className="flex gap-1">
              <Star/>
              <Star/>
              <Star/>
              <Star/>
              <Star/>
            </div>
          </div>
          <blockquote className="mt-10 font-display text-4xl font-medium tracking-tight text-slate-900 sm:text-center">
            <p>“Podoba mi się, że otrzymałam taka pomoc jaką mi zaproponowano. Samemu nic by mi się nie udało zdziałać.”</p>
          </blockquote>
          <figcaption className="mt-10 flex items-center sm:justify-center">
            <div className="overflow-hidden rounded-full bg-slate-200 text-2xl p-2 w-12 h-12 text-center">
              B
            </div>
            <div className="ml-4">
              <div className="text-base font-medium leading-6 tracking-tight text-slate-900">Beata</div>
              <div className="mt-1 text-sm text-slate-600">Listopad 2022</div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>

  )
}

const Star = () => (
  <svg aria-hidden="true" viewBox="0 0 20 20" className="h-5 w-5 fill-current">
    <path
      d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
  </svg>
)
