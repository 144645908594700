import React from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {graphql, Link, useStaticQuery} from "gatsby";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {Link as ScrollLink} from "react-scroll/modules";
import _ from "lodash";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


export default function AboutClaims() {

  const query = useStaticQuery(graphql`
    query {
      allContentfulOdszkodowanieSlot(filter: {type: {eq: "about-claim"}}) {
        nodes {
          id
          type
          section
          header
          content {
            raw
          }
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

  const data:{section: string, header:string, content: any, image:any}|undefined = _.first(query.allContentfulOdszkodowanieSlot.nodes);

  return (
    <div id="roszczenia" className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-32">

      <div className="flex flex-row items-start">
        <div className="md:w-1/2 w-full">
          <h2 className="text-sm text-gray-500 uppercase py-2">{data?.section}</h2>
          <h3 className="text-3xl font-semibold font-serif">{data?.header}</h3>

          <div className="pt-12 whitespace-pre-line">
            {documentToReactComponents(JSON.parse(data?.content.raw))}
          </div>
          <p className="pt-8 text-primary flex items-center cursor-pointer">
            <ChevronRightIcon className="w-4 h-4"/> <ScrollLink to='jak-otrzymac-odszkodowanie' className="" smooth={true} duration={500} offset={-50} >Dowiedz się więcej</ScrollLink>
          </p>
        </div>
        <div className="hidden md:block px-9 w-1/2">
          <GatsbyImage alt={data?.image.title}
                       image={data?.image.gatsbyImageData}
                       className="object-scale-down	w-full"
                       loading={"eager"}/>
        </div>

      </div>
    </div>



  )
}
