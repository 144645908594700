import React, {useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import {graphql, Link, useStaticQuery} from "gatsby";
import {CheckIcon, ChevronRightIcon} from "@heroicons/react/outline";
import {MeetingButton} from "./MeetingButton";
import _ from "lodash";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

export default function Howto() {

  const [step, setStep] = useState(1);

  const query = useStaticQuery(graphql`
    query {
      allContentfulOdszkodowanieHowto {
        nodes {
          idx
          name
          description {
            raw
          }
        }
      }
    }
  `)

  const data:{idx: number, name:string, description: any}[]|undefined = query.allContentfulOdszkodowanieHowto.nodes;

  // @ts-ignore
  const steps: any[] = data?.sort((a, b) => a.idx-b.idx)
    .map((value, index) => {
      return { id: value.idx, name: value.name, content: value.description.raw, onClick: () => {setStep(value.idx)}, status: step >= value.idx ? 'complete' : 'upcoming' }
    });

  const selected = steps.filter(value => value.id === step).pop();

  return (
    <div id="jak-otrzymac-odszkodowanie" className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-32">

      <h2 className="text-sm text-gray-500 uppercase py-2">Zrozumieć roszczenia</h2>
      <h3 className="text-3xl font-semibold font-serif">Jak otrzymać odszkodowanie?</h3>

      <div className="space-y-8">
      <nav aria-label="Progress" className="pt-8" >
        <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative md:flex md:flex-1 cursor-pointer " onClick={event => step.onClick()}>
              {step.status === 'complete' ? (
                <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                    <span className="text-indigo-600">0{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">0{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>

        <div className="space-y-8">
          <h4 className="text-xl font-semibold font-serif">{selected.name}</h4>

          <div className="prose  whitespace-pre-line">
            {documentToReactComponents(JSON.parse(selected.content))}
          </div>

        </div>

      <MeetingButton/>
      </div>

    </div>

  )
}
