import {Link} from "gatsby";
import React from "react";


export const MeetingButton = () => (
  <div className="rounded-md shadow bg-primary">
    <Link to='umow-spotkanie'
          className="w-full cursor-pointer flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-kgk-gold hover:bg-kgk-gold md:py-4 md:text-lg md:px-10"
    >
      Umów spotkanie weryfikujące roszczenie
    </Link>
  </div>
)
