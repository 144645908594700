import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {MeetingButton} from "./MeetingButton";

export default function Faq() {
  return (
    <div className="py-12 border-t-2 border-gray-200">
      <div id="faq" className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-16">


        <h2 className="text-sm text-gray-500 uppercase py-2">Zrozumieć roszczenia</h2>
        <h3 className="text-3xl font-semibold font-serif">Często zadawane pytania</h3>

        <ul className="prose pt-8">
          <li>
            <h4>Czy należy mi się odszkodowanie?</h4>
            <p>
              Jeśli w wypadku spowodowanym przez kogoś innego poniesiesz stratę materialną, doznasz obrażeń ciała lub uszczerbku na zdrowiu, prawdopodobnie należy Ci się:
              Zadośćuczynienie za doznaną krzywdę;
              Zwrot kosztów leczenia, rehabilitacji i opieki;
              Zwrot wartości rzeczy zniszczonych w wyniku wypadku;
              Wyrównanie utraconych dochodów i renta, jeśli całkowicie lub częściowo utracisz zdolność do pracy zarobkowej;
              Renta z tytułu zwiększonych potrzeb, jeżeli np. koszty leczenia będą ponoszone przez długi okres, co negatywnie wpłynie Twoją sytuację materialną.
            </p>
          </li>
          <li>
            <h4>Jak długo będę czekać na odszkodowanie?</h4>
            <p>
              Z naszego doświadczenia, w 80% przypadków wypłata może nastąpić w czasie krótszym niż 3 miesiące. Wiele zależy od kompletności i poprawności dokumentacji. Pomożemy Ci dopełnić wszystkich formalności tak, aby uniemożliwić ubezpieczycielowi przeciąganie sprawy.
            </p>
          </li>
          <li>
            <h4>Jakie mam szanse na odszkodowanie?</h4>
            <p>
              Występując samodzielnie z roszczeniem do firmy ubezpieczeniowej, w pojedynkę mierzysz się z potężną instytucją finansową. Kończy się to często odmową wypłaty lub zaniżeniem odszkodowania.
            </p>
            <p>
              Oddając sprawę sprawę nam, zyskujesz pomoc doświadczonych prawników, którzy w Twoim imieniu walczą o jak najwyższe odszkodowanie. Dla ubezpieczycieli jesteśmy trudnym przeciwnikiem. Twoje szanse rosną wielokrotnie.
            </p>
          </li>
          <li>
            <h4>Jaki jest koszt takiej sprawy?</h4>
            <p>
              Nie wymagamy żadnych opłat wstępnych ani zaliczek. Bezpłatne są również weryfikacja dokumentów, analiza i prowadzenie sprawy. Rozliczamy się dopiero po wypłacie odszkodowania – na zasadzie prowizji za sukces. Zasada jest prosta: nie wygramy – nie płacisz.
            </p>
          </li>
          <li>
            <h4>Ile wynosi prowizja?</h4>
            <p>
              Kwota prowizji zależy od złożoności konkretnego przypadku i nakładu pracy, jaką trzeba włożyć w prowadzenie sprawy. Jej wysokość określamy indywidualnie przed podpisaniem umowy.
            </p>
          </li>

        </ul>

      </div>
    </div>

  )
}

