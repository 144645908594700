import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {MeetingButton} from "./MeetingButton";

export default function CallToAction() {
  return (
    <div className="py-48 bg-[#F0EAE3]/10">
      <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-2 relative space-y-12">

        <h2 className="font-display text-5xl font-extrabold text-center tracking-tight text-[#ed2978] sm:w-3/4 sm:text-6xl md:w-2/3 lg:w-auto">
          Nie czekaj dłużej!
        </h2>


        <MeetingButton/>



      </div>
    </div>

  )
}

