import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import SeoHelmet from "../components/SeoHelmet";
import MainSlider from "../components/MainSlider";
import AboutClaims from "../components/AboutClaims";
import AboutUs from "../components/AboutUs";
import Howto from "../components/Howto";
import Testimony from "../components/Testimony";
import CallToAction from "../components/CallToAction";
import Faq from "../components/Faq";

const IndexPage = () => (
  <Layout spa={true}>
    <SeoHelmet title="Odszkodowanie.org - Odszkodowania oraz roszczenia za szkody" />

    <main>
      <MainSlider/>

      <AboutClaims/>
      <AboutUs/>
      <Howto/>
      <Testimony/>
      <CallToAction/>
      <Faq/>


    </main>


  </Layout>
)

export default IndexPage
